import { render, staticRenderFns } from "./NewProviderDetails.vue?vue&type=template&id=89c909c8&scoped=true&"
import script from "./NewProviderDetails.vue?vue&type=script&lang=js&"
export * from "./NewProviderDetails.vue?vue&type=script&lang=js&"
import style0 from "./NewProviderDetails.vue?vue&type=style&index=0&id=89c909c8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89c909c8",
  null
  
)

export default component.exports